import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL,apiSigningKey,LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
declare const CryptoJSAesJson;

@Injectable({
  providedIn: 'root'
})
export class KioskapiService {

  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private pageLoaderService: PageLoaderService,
    private router: Router,
    private store: LocalStoreService,
    private modalController: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
  ) { }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let user = this.store.get('user');
    const signingKey = apiSigningKey;
    const timestamp = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const body = payload ? JSON.stringify(payload) : '';
    const data = `${timestamp}|${body}`;
    const signature = CryptoJS.HmacSHA256(data, signingKey).toString(CryptoJS.enc.Hex);
    let options: any = {
    headers: new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${user.token}`,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept-Language': this.store.getLanguageUuid(),
    'Accept-Language-Code': this.store.getLanguageCode(),
    'Reference': 'Kiosk',
    'X-Signature': signature,
    'X-Timestamp': timestamp,
    })
    }
    switch (method) {
    case 'get':
    return this.httpClient.get(API_URL + endPoint, options ).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'post':
    return this.httpClient.post(API_URL + endPoint, payload,options).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'put':
    return this.httpClient.put(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'patch':
    return this.httpClient.patch(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'delete':
    return this.httpClient.delete(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    default:
    return null;
    }
    }
    restaurant_operational():Observable<any>{
      let restaurantDetails = this.store.getRestaurant();
      let param = {
        "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
      }
     return this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
  
    }
  
    menu_items():Observable<any>{
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
      let user_information_uuid = localStorage.getItem("user_information_uuid");
      let payload = { "ref": "kiosk", "master_restaurant_uuid": master_restaurant_uuid, "user_information_uuid": user_information_uuid };
      return this.apiReq('post', 'mastermenuitem/getpagermenuitemsbyrestaurantuuid', payload)
    //  return this.apiReq('post', 'mastermenuitem/getcategorymenuitemsbyrestaurantuuid', payload)
  
    }
  
    by_uuid():Observable<any>{
      let param = {
        "ref": "kiosk",
        master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid")
      }
     return this.apiReq('post', 'masterrestaurant/getbyuuid', param)
    }

    appsettings():Observable<any>{
      let countryData = this.store.getCountry();
      let countryUuid = countryData['master_country_uuid'];
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      return this.apiReq('get', 'payment/appsetting?cid=' + countryUuid +'&rid='+master_restaurant_uuid+'&type='+"mobile_kiosk")
    }
  
  
    kiosk_setting():Observable<any>{
      let countryData = this.store.getCountry();
      let countryUuid = countryData['master_country_uuid'];
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
      // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
  
    return  this.apiReq('get', 'payment/kiosksetting?cid=' + countryUuid)
  
    }
  
    cart_available():Observable<any>{
      let restaurantDetails = this.store.getRestaurant();
      let orderitem = this.store.getUserCart();
      let data = {
        master_restaurant_uuid: restaurantDetails.master_restaurant_uuid,
        orders: orderitem[0]
      }
     return  this.apiReq('post', 'orderinfo/isitemavailable', data)
     }
  
     restuarant_setting():Observable<any>{
      let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
     return this.apiReq('post', 'masterrestaurant/getrestaurantsetting', data)
     }
  
  
  
     update_order(data):Observable<any>{
      return this.apiReq('post', 'orderinfo/updatekioskorderinfostatus', data)
     }
  
     create_kiosk_order(data):Observable<any>{
     return  this.apiReq('post', 'orderinfo/createkioskorder', data)
     }

  
    item_detail(data){
      return this.apiReq('post', 'mastermenuitem/getitemdetailbyitemuuid', data)
    }

    logEvents(screen,payment?, payment_status?) {
      let payment_response = Object.assign({},payment);
      let restaurant = this.store.get("restaurant")
      let terminalid = this.store.get("terminalid")
      let session_id = this.store.get("sessionId")
      const analytics = getAnalytics();
      logEvent(analytics, 'logEvents', {
        "terminal_id":terminalid ,
        "session_id": session_id,
        "restaurant_uuid": restaurant.master_restaurant_uuid,
        "restaurant_status":restaurant.master_restaurant_status,
        "request_from": screen,
        "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {},
        "payment": payment_response,
        "payment_status":payment_status
      })
    }
    
  //Log API Crash/Exception/Error
  audit_log(screen,payment?,payment_status?){
    let payment_response =Object.assign({},payment );
  let restaurant = this.store.get("restaurant")
  const payload = {
    "terminal_id": this.store.get("terminalid") ,
    "session_id": this.store.get("sessionId"),
    "restaurant_uuid": restaurant.master_restaurant_uuid,
    "restaurant_status":restaurant.master_restaurant_status,
    "request_from": screen,
    "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {},
    "payment": payment_response,
    "payment_status":payment_status
  }
  
  this.apiReq('post', 'ssouserinformation/kioskauditlog', payload)
  .subscribe(
  (res) => {
  }, error =>{
  },
  );
   
  }
  
  }
  

