
import { Component } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { images_display_timer, menu_items_timer, telemetryTimeout } from '../common/api';
import { TranslateService } from '@ngx-translate/core';
import { PageLoaderService } from '../services/page-loader.service';
import { environment } from 'src/environments/environment';
import { AuditLogService } from '../services/audit-log.service';
import { KioskapiService } from '../services/kioskapi.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  params:any;
  name:any;
  menu_item: boolean;
  telemetry_blocker = false;
  forced_transmission = "";
  payment_progress_txt = "";
  payment_progress = "";
  payment_terminal_status = "";
  payment_terminal_status_txt = "";
  firmware_updating = false;
  software_version = "";
  ordering_avail = true;
  time_check = "OK";
  payment_initiated = false;
  check_nega = false;
  wait_card = false;
  telemetry_poller: any;
  maintain_timer: any;
  is_maintaince_mode_on = true;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  counter;
  terminalid = "1";
  restaurant_mode;
  balance_poller: any;
  balance;
  refund_progress_txt = "";
  modal_box = false;
  modal_type = "OK";
  payment_error: any;
  proc_card_idi;
  cartItems_create_order: any = [];
  interval: any;
  timeOutApiSession = false;
  balance_blocker = false;
  balance_shown = "";
  show_balance = false;
  balance_sub: any;
  inavail_mode = "REPLENISH";
  terminalResponse: any;
  hide_cancel_button: any;
  payprog_lock: any;
  subtype: any;
  deducted: any;
  attempted: any;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  restaurant_status_interval: any;
  obj_restaurantDetails;
  time_left_counter: any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  temp_balance: any;
  temp_total: any;
  _suica_alert: any;
  suica_alert_flag = 0;
  app_settings;
  ServiceID: any;
  is_restaurant_closed_status = "NO";
  obj_is_restaurant_closed;
  order_UUID
  isPaymentFailed;
  ella_max_drinks : any;
  kiosk_banner_image=""
  display_images:any;
  menuItems_timer;
  restaurantSetting;
  mobile_redirection_url = '';
  no_kiosk_gif = "";
  empty_mobile_redirection_url;
  menuItems_length;
  one_pager_kiosk: any;
  cat_show: string;
  menuItems_withoutCat:any = [];
  showEllaSoldOutPopup = false;
  showEllaExcededMaxQueuedRrdersPopup = false;
  ellaExcededMaxQueuedRrdersPopupMessage = '';

  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private api: ApiService,
    public alertController: AlertController,
    private translate: TranslateService,
    private pageLoaderService: PageLoaderService,
    public auditlogServices: AuditLogService,
    private kiosk_apiService : KioskapiService
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
    this.check_auth();
    this.getAppSettings();
  }

  ngOnInit() {
    this.restaurant_setting()
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.check_auth();
    this.store.remove("totalCartAmount");
    this.store.setCart([]);
  }

  is_restaurant_closed() {
    this.obj_is_restaurant_closed = this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
          
          }
        },
      );
  }

  ellaMaxDrinks(){
    this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
         
          if (res.statusCode == 200) {
            this.ella_max_drinks = res.ella_max_drinks
          }
        },
      );
  }


  ionViewWillEnter() {
    this.store.setUserCart([]);
    this.ellaMaxDrinks()
    this.getMenuItems();
    this.menu_item = false;
    this.telemetry_poller = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);
    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 9000)
      // this.display_images =setInterval(()=>{
      //   this.getAppSettings();
      // },images_display_timer)
      this.menuItems_timer =setInterval(()=>{
        this.getMenuItems();
      },menu_items_timer)

    this.orderType = this.store.get('order_type');
    this.check_auth();
    this.getCartItems();
    if (this.cartItemCount == 0) {
    }
    this.kiosk_apiService.audit_log("HOME");
    this.kiosk_apiService.logEvents("HOME")
  }

  getMenuItems() {
    this.kiosk_apiService.menu_items().subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          if(this.one_pager_kiosk == false || this.one_pager_kiosk == "false" || this.one_pager_kiosk == null || this.one_pager_kiosk == undefined){
            if (response.final_response) {
              this.cat_show = "true";
              this.menu_item = true;
              this.menuItems = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.menu_item = true;
              this.cat_show = "true";
              this.menuItems = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }else{
            if (response.final_response) {
              this.menu_item = true;
              this.cat_show = "false";
              this.menuItems_withoutCat = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.cat_show = "false";
              this.menu_item = true;
              this.menuItems_withoutCat = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }
        }
      },
        error => {
        }
      );
  }

  check_auth() {
    if (!this.kioskauthservice.is_logged_in()) {
      this.kioskauthservice.logout();
    }
  }

  async redirectOnDetailPage(item) {
    this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.redirectOnItemDetailPage(item);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
      if (item.master_menu_item_is_combo == "YES") {
        if (item.master_food_category_uuid == '12844a2db14f510f90333983f1fa07ad' || item.master_food_category_uuid == '1be5953589919b2b686f94e2b68dccea' || item.master_food_category_uuid == 'a16b3341e0203ea1e3435c5c04b177bd') {
          this.router.navigateByUrl(`product-combo-detail?id=` + item.master_menu_item_uuid);
        }
        else {
          
          this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
        }
      }
      else {
        this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
    }
  }

  getCartItems() {
    let cartDetail = this.store.getUserCart();
    this.cartItems = cartDetail;
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.cartItemCount = +v[0].total_quantity + +this.cartItemCount;
      this.totalCartAmount = +v[0].total + +this.totalCartAmount;
    })
  }

  getTotalQuantity(item) {
    return item[0].total_quantity;
  }

  checkOut() {
    this.kiosk_apiService.audit_log("CHECKOUT");
    this.kiosk_apiService.logEvents("CHECKOUT")
    this.kiosk_apiService.restaurant_operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.router.navigate([`payment-method`]);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {
        }
      );
  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  doRefresh(event) {
    this.kiosk_apiService.by_uuid()
      .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          event.target.complete();
          this.maintain_timer = setInterval(
            () => {
              if (this.shop.master_restaurant_status == 'M_ON') {
                this.is_maintaince_mode_on = true;
              }
              else {
                this.is_maintaince_mode_on = false;
              }
              this.is_maintaince_mode_on = false;
            }
            , telemetryTimeout);
        } else {
          event.target.complete();
        }
      }, err => {
        event.target.complete();
      });
  }

  
  getRestaurantDetails() {
    this.obj_restaurantDetails = this.kiosk_apiService.by_uuid()
      .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (res?.aaData?.ella_orders_blocked && (res?.aaData?.ella_orders_blocked == 'true' || res?.aaData?.ella_orders_blocked == true)) {
            this.showEllaSoldOutPopup = true;
          } else {
            this.showEllaSoldOutPopup = false;
          }

          if (res?.aaData?.ella_exceded_max_queued_orders && (res?.aaData?.ella_exceded_max_queued_orders == 'true' || res?.aaData?.ella_exceded_max_queued_orders == true)) {
            this.showEllaExcededMaxQueuedRrdersPopup = true;
            this.ellaExcededMaxQueuedRrdersPopupMessage = res?.aaData?.ella_exceded_max_queued_orders_message
          } else {
            this.showEllaExcededMaxQueuedRrdersPopup = false;
          }
          
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = true;
          }
          else {
            this.is_maintaince_mode_on = false;
          }
          if(res.aaData.enable_mobile_kiosk === 'NO'){
          if(this.mobile_redirection_url !== null && this.mobile_redirection_url !== undefined && this.empty_mobile_redirection_url.length > 0 && (this.mobile_redirection_url.includes('http://') || this.mobile_redirection_url.includes('https://')) ){
            console.log("logout started")
            this.store.hardlogout()
            location.href = this.mobile_redirection_url
            this.no_kiosk_gif = "false";
          }
          else{
            this.no_kiosk_gif = "true";
          }
        }else{
          this.no_kiosk_gif = "false";
        }
        // console.log(  this.no_kiosk_gif )
        }
      }, error => {
      });
  }

  getAppSettings() {
    this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;
        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.kiosk_banner_image=response.aaData.app_settings.app_store_banner_description_mobile_kiosk ? response.aaData.app_settings.app_store_banner_description_mobile_kiosk : environment.HOME_BANNNER
      }
    }, err => {
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  ionViewDidLeave() {
    this.obj_is_restaurant_closed.unsubscribe();
    this.obj_restaurantDetails.unsubscribe();
    clearInterval( this.display_images)
  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;
      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        window.location.reload();
      }
    });
  }

 
  async technical_error(message?) {
    let errorMessage = '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>';
    if (message) {
      errorMessage = message;
    }
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: errorMessage,
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/home'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

 
  restaurant_setting() {
    this.kiosk_apiService.restuarant_setting()
      .subscribe(
        (res) => {
          this.restaurantSetting = res.aaData.module_permissions
          this.mobile_redirection_url = res.aaData.module_permissions.mobile_kiosk_order_redirection
          this.empty_mobile_redirection_url = this.mobile_redirection_url.trim()
          this.one_pager_kiosk = this.restaurantSetting.one_pager_kiosk
        },
        error => {
        }
      );
  }

  redirectOutSide() {
    if(this.mobile_redirection_url !== null && this.mobile_redirection_url !== undefined){
      setTimeout(() => {     
        console.log("logout started")
        this.store.hardlogout()
        location.href = this.mobile_redirection_url
      }, 100);
    }
  }

}
