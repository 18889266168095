import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL,apiSigningKey,LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
declare const CryptoJSAesJson;
 

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  Orderstatus = new Subject();
  Orderuuid = new Subject();
  confirmationResult: any;

  constructor(
    private httpClient: HttpClient,
private toastController: ToastController,
private pageLoaderService: PageLoaderService,
private router: Router,
private store: LocalStoreService,
private modalController: ModalController,
private translate: TranslateService,
public alertController: AlertController,
  ) { }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    const signingKey = apiSigningKey;
    const timestamp = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const body = payload ? JSON.stringify(payload) : '';
    const data = `${timestamp}|${body}`;
    const signature = CryptoJS.HmacSHA256(data, signingKey).toString(CryptoJS.enc.Hex);
    let options: any = {
    headers: new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
    // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept-Language': this.store.getLanguageUuid(),
    'Accept-Language-Code': this.store.getLanguageCode(),
    'Reference': 'Kiosk',
    'X-Signature': signature,
    'X-Timestamp': timestamp,
    })
    }
    switch (method) {
    case 'get':
    return this.httpClient.get(API_URL + endPoint, options ).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'post':
    return this.httpClient.post(API_URL + endPoint, payload,options).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'put':
    return this.httpClient.put(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'patch':
    return this.httpClient.patch(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'delete':
    return this.httpClient.delete(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    default:
    return null;
    }
    }

   
//Log API Crash/Exception/Error
logException(exceptionAt, exceptionData, postedData?){
 
  const exceptionPayload = {
  exceptionOccuredFor: exceptionAt?exceptionAt: null,
  exceptionResponse: exceptionData?exceptionData: null,
  dataSentByUser: postedData?postedData : null,
  dateTime: new Date(),
  cartData: this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : null,
  isThreadOfAuditLogId: this.store.get('Current_audit_log')?this.store.get('Current_audit_log'):null,
  orderId: this.store.get('orderId'),
  'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
  cart_details : JSON.parse(localStorage.getItem("30b1a711dec4bd62b2b9ac89f7bfb1da")),
  terminal_id : localStorage.getItem("terminalid"),
  restaurant : localStorage.getItem("restaurant"),
   
  }
  const payload = {
  message: exceptionData?exceptionData.message:exceptionAt,
  master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid"),
  audit_type: 'ERROR',
  new_value: exceptionPayload?exceptionPayload:null
  }
  console.log("logException", exceptionPayload)
  this.apiReq('post', 'ssouserinformation/auditlog', payload)
  .subscribe(
  (res) => {
  //Exception Logged
  }, error =>{
  },
  );
   
  }
  //API for logging user actions & data used for payments
  logUserPaymentLogs(loggedAt, paramsData, resData){
  const logsPayload = {
  dataSentByUser: paramsData,
  dataRecivedFromAPI: resData,
  dateTime: new Date(),
  cartData: this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : null,
  isThreadOfAuditLogId: this.store.get('Current_audit_log'),
  orderId: this.store.get('orderId'),
  'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
  cart_details : JSON.parse(localStorage.getItem("30b1a711dec4bd62b2b9ac89f7bfb1da")),
  terminal_id : localStorage.getItem("terminalid"),
  restaurant : localStorage.getItem("restaurant"),
  }
  const payload = {
  message: loggedAt,
  master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid"),
  audit_type: 'KIOSK_LOG',
  new_value: logsPayload
  }
  console.log("logUserPaymentLogs", payload);
  this.apiReq('post', 'ssouserinformation/auditlog', payload)
  .subscribe(
  (res) => {
  //Exception Logged
  }, error =>{
  },
  );
  }
   
  //API for logging user actions & data used for create order using observable to get audit_log id
  logCreateOrderLogs(loggedAt, userData):Observable<any>{
  const logsPayload = {
  dataSentByUser: userData,
  dateTime: new Date(),
  cartData: this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : null,
  'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
  cart_details : JSON.parse(localStorage.getItem("30b1a711dec4bd62b2b9ac89f7bfb1da")),
  terminal_id : localStorage.getItem("terminalid"),
  restaurant : localStorage.getItem("restaurant"),
  }
  const payload = {
  message: loggedAt,
  master_restaurant_uuid: localStorage.getItem("master_restaurant_uuid"),
  audit_type: 'KIOSK_LOG',
  new_value: logsPayload
  }
  console.log("logUserPaymentLogs", payload);
  return this.apiReq('post', 'ssouserinformation/auditlog', payload);
  }
}
